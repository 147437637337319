@import '../../styles/colours.scss';

.toggle {
    background: #8c8c8c;
	width: 50px;
	height: 22px;
	border-radius: 11px;
	position: relative;
	
	&:hover {
		cursor: pointer;
	}

    &.active {
        background: $primary_colour;
        // The inner ball in the toggle
        > div {
            left: calc(100% - 19px);
        }
	}
}

.toggle__ball {
    background: #fff;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 3px;
	left: 3px;
	border-radius: 50%;
	transition: all 0.3s ease;
}