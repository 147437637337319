@import "../../styles/colours.scss";

.userManagement__actionsWrapper {
    text-align: right;

    > div {
        display: inline-block;
        vertical-align: middle;
    }
}

.userManagementCard__header {
    background: $primary_colour;
}

.userManagementCard__buttonWrapper {
    text-align: right;
    padding: 8px;

    // buttons
    span {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        background: $primary_colour;
        padding: 8px 16px;
        border-radius: 5px;
        text-transform: uppercase;
        margin-left: 8px;

        &:hover {
            cursor: pointer;
            background: lighten($primary_colour, 10%);
        }
    }
}

.userManagementCard__removeButton {
    &:hover {
        cursor: pointer;
    }
}

.userManagementCard__confirmButtonWrapper {
    > span {
        display: inline-block;
        vertical-align: middle;

        &:hover {
            cursor: pointer;
        }
    }
}

.userManagementAddUser__button {
    display: inline-block;
    font-size: 12px;
    color: #fff;
    background: $primary_colour;
    padding: 8px 16px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-left: 8px;

    &:hover {
        cursor: pointer;
        background: lighten($primary_colour, 10%);
    }
}

.userManagement__deleteUser {
    text-align: left;

    > span {
        display: inline-block;
        vertical-align: middle;
    }
}