@import '../../styles/colours.scss';

.profile {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
}

.profile__toggle {
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
}

.profile__content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.15s ease-in;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;

    &.active {
        max-height: 55px;
        border: 1px solid $primary_colour;
        box-shadow: 1px 1px 1px lighten($primary_colour, 20%);
    }
}