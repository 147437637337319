@import '../../styles/colours.scss';

.noAccess__wrapper {
    height: 200px;
    text-align: center;
    width: 100%;
    position:relative;
    border-color: $error_colour;
}

.noAccess__innerWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    width: 100%;
    padding: 7px;

    > span {
        display: table-cell;
        vertical-align: middle;
        padding-right: 14px;
    }

    > p {
        display: table-cell;
        vertical-align: middle;
    }

    @media (min-width: 768px) {
        width: auto;
    }
}