@import '../../../styles/colours.scss';

.markManyAsDelivered__button {
    width: 215px;
    height: 36px;
    position: relative;
}

.markManyAsDelivered__spinner {
    position: absolute;
    top: 8px;
    left: calc(50% - 0.625rem);
}

.markManyAsDelivered__textArea {
    border-color: pink !important;
    color: black;
    margin-top: 16px;
}

.markManyAsDelivered__table {
    margin-top: 16px;
    width: 100%;
    border-bottom: 1px solid $primary_colour;
    border-left: 1px solid $primary_colour;
    border-radius: 100px;

    td {
        vertical-align: middle;
        border-right: 1px solid $primary_colour;
        border-top: 1px solid $primary_colour;
        padding: 8px;
    }
}
