@import '../../styles/colours.scss';

.modal {
    background: #fff;
}

.modal__header {
    background: $primary_colour;
    border-bottom: none;
    color: #fff;
}

.modal__footer {
    border-top: 1px solid $primary_colour;
}

.modal__button {
    position: relative;
    color: #fff;
    background-color: $primary_colour;

    &.isLoading {
        color: $primary_colour;
    }

    &:hover {
        background-color: lighten($primary_colour, 10%);
        cursor: pointer;

        &.isLoading {
            color: lighten($primary_colour, 10%);
        }
    }
}

.modal__loader {
    display: inline-block;
    padding-left: 5px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(calc(-50% - 3px));
}